import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'antd';

const SyncLogDetailModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [log, setLog] = useState(null);

    function showModal(text) {
        setVisible(true);
        setLog(text);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function renderContent() {
        if (!log) {
            return null;
        }
        const list = log
            .split('\n')
            .filter(line => (!!line || !!line.trim()) && line !== 'null')
            .map((item, index) => {
                return <li key={index}>{item}</li>;
            });

        return <ul>{list}</ul>;
    }

    return (
        <Modal
            visible={visible}
            title="员工同步日志详情"
            destroyOnClose
            onCancel={closeModal}
            width={1000}
            okButtonProps={{style: {display: 'none'}}}
        >
            {renderContent()}
        </Modal>
    );
});

SyncLogDetailModal.displayName = 'SyncLogDetailModal';

SyncLogDetailModal.propTypes = {};

export default SyncLogDetailModal;
